var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择商品分类",
            visible: _vm.dialogVisible,
            width: "960px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "goods-cate-modal" }, [
            _c("div", { staticClass: "goods-cate-modal-body" }, [
              _c(
                "div",
                { staticClass: "goods-cate-form-wrapper" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.searchForm,
                        "label-position": "right",
                        inline: ""
                      }
                    },
                    _vm._l(_vm.goodsCateFormItems, function(item) {
                      return _c(
                        "el-form-item",
                        {
                          key: item.label,
                          style: item.itemStyle,
                          attrs: { prop: item.prop }
                        },
                        [
                          item.type === "select"
                            ? _c("v-autocomplete", {
                                style: item.style,
                                attrs: {
                                  items: item.options,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: item.label,
                                  disabled: item.disabled,
                                  outlined: "",
                                  "item-text": "label",
                                  "item-value": "value",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据"
                                },
                                model: {
                                  value: _vm.searchForm[item.prop],
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, item.prop, $$v)
                                  },
                                  expression: "searchForm[item.prop]"
                                }
                              })
                            : _c("v-text-field", {
                                style: item.style,
                                attrs: {
                                  label: item.label,
                                  disabled: item.disabled,
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32
                                },
                                model: {
                                  value: _vm.searchForm[item.prop],
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, item.prop, $$v)
                                  },
                                  expression: "searchForm[item.prop]"
                                }
                              })
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "actions-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSearch }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onReset }
                        },
                        [_vm._v("重置")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "goods-cate-table-wrapper" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "24px" },
                      attrs: {
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中",
                        "row-style": { height: "20px" },
                        "cell-style": { padding: "0px" },
                        "header-cell-style": { background: "#e5f2ff" },
                        "highlight-current-row": "",
                        "header-cell-class-name": "normal-table-header",
                        height: _vm.tableHeight,
                        "row-key": "id",
                        data: _vm.tableData
                      },
                      on: { "current-change": _vm.onCurrentChange }
                    },
                    [
                      _vm._l(_vm.columns, function(item) {
                        return [
                          item.prop === "updateTime"
                            ? _c("el-table-column", {
                                key: item.prop,
                                attrs: {
                                  "show-overflow-tooltip": true,
                                  label: item.label
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  row.updateTime
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _c("el-table-column", {
                                key: item.prop,
                                attrs: {
                                  "show-overflow-tooltip": true,
                                  label: item.label,
                                  prop: item.prop,
                                  fixed: item.fixed,
                                  width: item.width
                                }
                              })
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "goods-cate-modal-footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: !_vm.isCurrentRow },
                    on: { click: _vm.onOk }
                  },
                  [_vm._v("确定")]
                ),
                _c("el-button", { on: { click: _vm.onCancel } }, [
                  _vm._v("取消")
                ])
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }