<!--
 * @Author: 刘锦
 * @Date: 2020-09-18 16:17:38
 * @LastEditTime: 2022-05-26 16:26:53
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\views\workbench\business\sign-up\components\goods-cate-modal.vue
-->
<template>
  <div>
    <el-dialog
        title="选择商品分类"
        :visible.sync="dialogVisible"
        width="960px"
    >
      <div class="goods-cate-modal">
        <div class="goods-cate-modal-body">
          <div class="goods-cate-form-wrapper">
            <!-- 搜索框区 -->
            <el-form :model="searchForm" label-position="right" inline>
              <el-form-item
                  v-for="item of goodsCateFormItems"
                  :key="item.label"
                  :style="item.itemStyle"
                  :prop="item.prop"
              >
                <!-- <el-select
                    v-if="item.type === 'select'"
                    clearable
                    filterable
                    v-model="searchForm[item.prop]"
                    :placeholder="item.placeholder"
                    :disabled="item.disabled"
                    :style="item.style"
                >
                  <el-option v-for="(option, key) of item.options" :key="key" :label="option.label" :value="option.value" />
                </el-select> -->
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="searchForm[item.prop]"
                  :items="item.options"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="item.label"
                  :disabled="item.disabled"
                  :style="item.style"
                  outlined
                  item-text="label"
                  item-value="value"
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                ></v-autocomplete>
                <!-- <el-input
                    v-else
                    v-model="searchForm[item.prop]"
                    :disabled="item.disabled"
                    :placeholder="item.placeholder"
                    :style="item.style"
                /> -->
                <v-text-field
                  v-else
                  v-model="searchForm[item.prop]"
                  :label="item.label"
                  :disabled="item.disabled"
                  :style="item.style"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                >
                </v-text-field>
              </el-form-item>
            </el-form>
            <div class="actions-btn">
              <el-button @click="onSearch" type="primary">查询</el-button>
              <el-button @click="onReset" type="primary">重置</el-button>
            </div>
          </div>
          <div class="goods-cate-table-wrapper">
            <!-- 下表格区 -->
            <el-table
                style="width: 100%;margin-bottom:24px"
                border
                stripe
                size="mini"
                element-loading-text="拼命加载中"
                :row-style="{ height: '20px' }"
                :cell-style="{ padding: '0px' }"
                :header-cell-style="{ background: '#e5f2ff' }"
                highlight-current-row
                header-cell-class-name="normal-table-header"
                :height="tableHeight"
                row-key="id"
                :data="tableData"
                @current-change="onCurrentChange"
            >
              <template v-for="item of columns">
                <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'updateTime'" :label="item.label" :key="item.prop">
                  <template v-slot:default="{ row }">
                    <span>{{ row.updateTime | formatDate }}</span>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true" v-else :key="item.prop" :label="item.label" :prop="item.prop" :fixed="item.fixed" :width="item.width" />
              </template>
            </el-table>
          </div>
        </div>
        <div class="goods-cate-modal-footer">
          <el-button type="primary" @click="onOk" :disabled="!isCurrentRow">确定</el-button>
          <el-button @click="onCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "GoodsCateModal",
  data() {
    return {
      searchForm: {
        productId: "",
        name: "",
        goodsTypeId: ""
      },
      goodsCateFormItems: [
        {
          type: "select",
          label: "商品类型",
          prop: "goodsTypeId",
          placeholder: "商品类型",
          style: {
            width: "192px"
          },
          options: []
        },
        {
          label: "分类ID",
          type:'input',
          prop: "id",
          placeholder: "商品分类ID",
          style: {
            width: "192px"
          }
        },
        {
          type: "input",
          label: "商品分类",
          prop: "name",
          placeholder: "商品分类名称",
          style: {
            width: "192px"
          },
          options: []
        }
      ],
      tableData: [],
      columns: [
        {
          label: "分类ID",
          prop: "id"
        },
        {
          label: "分类名称",
          prop: "name"
        },
        {
          label: "赛道",
          prop: "businessName"
        },
        {
          label: "商品类型",
          prop: "goodsTypeText"
        },
        {
          label: "商品数",
          prop: "goodsCount",
          width: 80
        },
        {
          label: "更新时间",
          prop: "updateTime",
          width: 160
        }
      ],
      currentRow: {}
    }
  },
  props: {
    visible: Boolean,
    businessId: [String, Number],
    schoolId: [String, Number],
    goodBusinessId: [String, Number]
  },
  methods: {
    onOk() {
      const row = JSON.parse(JSON.stringify(this.currentRow))
      if( row.children && row.children.length ){
         this.$message.error("存在二级分类的情形下，不允许选择一级分类,请重新选择!")
        return;
      }
      this.$emit("on-ok", row)
    },
    onCancel() {
      this.$emit("update:visible", false)
    },
    onSearch() {
      this.getGoodsCateData()
    },
    onReset() {
      Object.keys(this.searchForm).forEach(key => (this.searchForm[key] = ""))
      this.getGoodsCateData()
    },
    onCurrentChange(row) {
      if (!row) return
      this.currentRow = row
    },

    //getFindAllGoodsCategory
    //getGoodsCategories
    // 获取商品分类表格数据
    async getGoodsCateData() {
      const params = {
        statusSchool: 1,
        ...this.searchForm
      }

      if( this.businessId=== '0' ){
        params["businessId"] = this.businessId
      }
      // 增加产品线赛道businessId入参
      // http://pm.dev.hqjy.cn/zentao/story-view-1392.html
      params.businessId = this.goodBusinessId
      //如果有搜索条件，那么走另外一个接口
      //goodsTypeId 商品类型
      if( this.searchForm.id || this.searchForm.name || this.searchForm.goodsTypeId ){
        const { data } = await this.$fetch("getGoodsCategories", params);
        this.tableData = data;
        return 
      }
      const { data } = await this.$fetch("getFindAllGoodsCategory", params)
      this.tableData = data
    }
  },


  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    isCurrentRow() {
      if (!this.currentRow) return false
      if ( this.currentRow.children && this.currentRow.children.length ){
        this.$message.error("存在二级分类的情形下，不允许选择一级分类,请重新选择!")
        return false;
      }
      return Object.keys(this.currentRow).length > 0
    },
    projectList() {
      return this.$store.state.workbench.projectList
    },
    goodsTypes() {
      return this.$store.state.workbench.goodsTypes
    },
    tableHeight() {
      return window.outerHeight - 420 + 'px'
    }
  },
  watch: {
    visible(val) {
    
      if (val) {
        for (const item of this.goodsCateFormItems) {
          if (item.prop === "productId") {
            item.options = this.projectList.map(item => ({
              label: item.fullName,
              value: item.id
            }))
          } else if (item.prop === "goodsTypeId") {
            console.log('this.goodsTypes', this.goodsTypes);
            let arr = []
            this.goodsTypes.map((item) => {
              if (item.id !== 3 && item.id !== 4) {
                arr.push(item)
              }
            })
            console.log('arr', arr);
            item.options = arr.map((item) => ({ value: item.id, label: item.name }))
            console.log('item.options', item.options);
          }
        }
        this.getGoodsCateData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-cate-modal {
  &-body {
    border-bottom: 1px solid #e1e4eb;
    .actions-btn {
      margin-bottom: 24px;
    }
  }
  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
  }
}
</style>
